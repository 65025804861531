import React, { useEffect } from "react";
import { PageTitle } from "../components/globals";
import Layout from "../components/layout";




const IndexPage = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://www.ocucowebdiary.net/widget?key=5169e8fadbe937879d952673ccfef26c&type=js&clear=1";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        const frame = document.querySelector("#wd_ediary_iframe")
        if(frame)frame.remove()
        document.body.removeChild(script);
      }

    },[])
    return (
        <Layout
        disableFooter
        title="Timebestilling"
        description="Bestill time enkelt og raskt hos oss i vår nettløsning"
        keywords={["timebestilling","bestilling","synstime","briller","linser"]}
      >
        <PageTitle>Bestill time</PageTitle>
      </Layout>
    )
}
export default IndexPage;